<template>
  <div>
    <b-row>
      
      <b-col md="6" v-for="(settingType, index) in settingTypes" :key="index" class="my-2" style="text-align: center;">

        <b-card class="h-100 ">
          <statistic-card-vertical
            icon="SettingsIcon"
            :statistic="settingType.replace(/([A-Z,'_'])/g, ' ').trim().toUpperCase() "
            statistic-title=""
            color="success"
          />
          <b-button
            variant="success"
           
            :to="{ path: '/settings/index/' + settingType}"
            >Show</b-button
          >
        </b-card>

        
      </b-col>
    </b-row>

    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="$store.state.Setting.showDismissibleAlert"
      @dismissed="$store.state.Setting.showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul
          v-for="(values, index) in $store.state.Setting.errors_back"
          :key="index"
        >
          <li v-for="(value, index) in values" :key="index">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
  
  <script>
  
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";

import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
 
    StatisticCardVertical,
  },

  mixins: [heightTransition],
  data() {
    return {
  
    
    }
  },
  computed: {
    settingTypes() {
      return this.$store.state.Setting.settingTypes
    },

  },
  watch: {},
  created() {
    this.$store.dispatch('Setting/getSettingTypes')

  }, 
 
  methods: {},
}
</script>
  
  <style scoped lang="scss">
</style>
  